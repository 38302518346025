<template>
    <div class="pb-5">
        <v-title title="Add Product Feed"></v-title>
        <title-box>
            <div class="row">
                <div class="col col-8">
                    <h1>Add Product Feed</h1>
                </div>
            </div>
        </title-box>
        <div class="container-fluid">
            <div class="mt-4">
                <div>
                    <product-feed-content/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProductFeedContent from '@/components/elements/product-feed/ProductFeedContent'

export default {
    name: 'AddProductFeed',
    components: { ProductFeedContent },
}
</script>
