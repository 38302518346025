<template>
    <div>
        <pretty-select
            v-if="shops && shops.length"
            label="applicationName"
            v-model="localValue"
            :options="shops"
            :required="true"
            placeholder="Application Name"
            :select-on-tab="true"
            class="application-selector"
            :class="{'application-selector--is': !!localValue}"
        ></pretty-select>
         <span class="error pretty-select-error" v-if="$v.$error">
            Application is required
        </span>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import PrettySelect from '../utils/PrettySelect'

export default {
    name: 'ApplicationSelector',
    props: {
        value: {
            default: null,
        },
        $v: {
            type: Object,
        },
    },
    components: { PrettySelect },
    computed: {
        ...mapState('shop', ['shops']),

        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss" scoped>
    .application-selector {
        margin-top: 20px;

        &--is {
            margin-top: -4px;
        }
    }
</style>
