<template>
  <div>
    <mercur-card>
      <!-- Loading  -->
      <div v-if="isLoading" class="text-center">
          <mercur-spinner></mercur-spinner>
      </div>
      <!-- Content -->
      <div v-if="!isLoading">
        <form class="row">
          <div class="col-12">
              <application-selector class="mb-3" v-model="application" :$v="$v.form.applicationId" />
          </div>
          <div class="col-12">
            <country-selector class="mb-3" v-model="form.countryCodes" :$v="$v.form.countryCodes" reducer="countryShortCode" :is-lower-case="false" :required="true" :multiple="true" />
          </div>
          <div class="col-12">
            <language-selector class="mb-3" v-model="form.language" :$v="$v.form.language" />
          </div>

          <!-- Editing product feed fields -->
          <div class="col-12 mt-4">
              <mercur-select v-model="form.configurationId" @selected="updateConfiguration">
                  <template #label>{{ isEditing ? 'Configuration Name': 'Select Configuration'}}</template>
                  <option v-for="config in configurations"
                             :key="config.configurationId"
                             :value="config.configurationId">
                      {{ config.configurationName }}
                  </option>
              </mercur-select>
          </div>
          <div v-if="form.configurationId" class="col-12 mt-4">
            <h3 class="font-weight-normal">Fields from selected configuration</h3>
            <div>

              <h3 class="mt-1 font-weight-normal">System Fields</h3>

              <div>
                <mercur-chip class="bg-yellow mt-2" v-for="field in systemFields.filter(field => selectedCurrentFields.includes(field.fieldName))" :key="field.fieldId">
                  {{ field.fieldName }}
                </mercur-chip>
              </div>

              <h3 class="mt-3 font-weight-normal">Custom Fields</h3>

              <div>
                <mercur-chip class="bg-yellow mt-2" v-for="field in customFields.filter(field => selectedCurrentFields.includes(field.fieldName))" :key="field.fieldId">
                  {{ field.fieldName }}
                </mercur-chip>
              </div>
            </div>
          </div>

          <!-- Data text area -->
          <div class="col-12">
              <mercur-textarea v-model="form.dataFields" :class="getValidationClass($v, 'dataFields')">
                  Order of provided data
                  <template #note>
                      <span class="form-error" v-if="!$v.form.dataFields.required">"productHash", "sku" and "quantity" are required</span>
                  </template>
              </mercur-textarea>
              <mercur-textarea v-model="form.productFeedData" :class="getValidationClass($v, 'productFeedData')">
                  Fields of provided data
                  <template #note>
                      <span class="form-error" v-if="!$v.form.productFeedData.required">"productHash", "sku" and "quantity" are required</span>
                  </template>
              </mercur-textarea>
          </div>

          <!-- Buttons -->
          <div class="mt-3 text-right col-12">
              <mercur-button v-if="hasPermission('MerchantCentral/updateProductFeed')" @click.prevent.native="isEditing ? update(false) : create(false)" :disabled="isDisabled" class="btn btn-primary">Save</mercur-button>
              <mercur-button v-if="hasPermission('MerchantCentral/updateProductFeed')"  @click.prevent.native="isEditing ? update(true) : create(true)" :disabled="isDisabled" class="btn btn-primary">Save and Publish</mercur-button>
          </div>
        </form>
      </div>
    </mercur-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required, requiredIf } from 'vuelidate/lib/validators'
import CountrySelector from '@/components/elements/CountrySelector'
import LanguageSelector from '@/components/elements/LanguageSelector'
import ApplicationSelector from '@/components/elements/ApplicationSelector'

export default {
    props: ['isEditing'],
    mixins: [FormMixin],
    name: 'ProductFeedContent',
    components: { CountrySelector, LanguageSelector, ApplicationSelector },
    data: () => ({
        isLoading: false,
        selectedCurrentFields: [],
        isPublishing: false,
        isDisabled: false,
        form: {},
        productFeedId: null,
    }),

    validations: {
        form: {
            productFeedId: {
                required: requiredIf(function () {
                    return this.isEditing
                }),
            },
            applicationId: {
                required,
            },
            configurationId: {
                required,
            },
            countryCodes: {
                required,
            },
            dataFields: {
                required,
            },
            language: {
                required,
            },
            productFeedData: {
                required,
            },
        },
    },

    methods: {
        ...mapActions('productfeed', ['getCurrentProductFeed', 'fetchFields', 'fetchConfigurations']),

        async updateConfiguration () {
            const foundConfiguration = await this.$store.getters['productfeed/getConfigurationById'](this.form.configurationId)

            if (!foundConfiguration) {
                this.$root.$emit('notification:global', {
                    message: 'An error occurred with selected configuration',
                    type: 'error',
                })
                return
            }
            const fields = this.$store.getters['productfeed/getFieldsByIds'](foundConfiguration.fields)
            const foundFields = fields ? fields.map(field => field.fieldName).join(';') : null

            this.selectedCurrentFields = foundFields
        },

        async update (publish) {
            this.$v.$touch()
            if (this.$v.$invalid || this.$v.$error) {
                return
            }
            this.isDisabled = true
            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.UPDATE.replace('{productFeedId}', this.productFeedId)
            const data = this.form
            data.productFeedData.split(';').join('\n')
            data.publish = publish

            this.post(url, data, 'Product feed was updated').then((res) => {
                if (res) {
                    if (publish) {
                        this.$root.$emit('notification:global', {
                            message: 'Product Feed Generation was started',
                        })
                        this.$router.push({
                            name: 'ProductFeedDetails',
                            params: {
                                productFeedId: this.productFeedId,
                            },
                        })
                        return
                    }
                    this.$router.replace({
                        name: 'ProductFeedsWrapper',
                    })
                }
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.isDisabled = false
            })
        },

        async create (publish) {
            this.$v.$touch()
            if (this.$v.$invalid || this.$v.$error) {
                this.$root.$emit('notification:global', {
                    message: 'Please check all fields',
                    type: 'error',
                })
                return
            }
            this.isDisabled = true
            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.CREATE
            const data = this.form
            data.productFeedData.split(';').join('\n')
            data.publish = publish

            this.post(url, data, 'Product feed was created').then((res) => {
                if (res) {
                    if (publish) {
                        this.$root.$emit('notification:global', {
                            message: 'Product Feed Generation was started',
                        })
                    }
                    this.$router.replace({
                        name: 'ProductFeedsWrapper',
                    })
                }
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.isDisabled = false
            })
        },
    },

    computed: {
        ...mapState('shop', ['shops']),
        ...mapState('productfeed', ['currentProductFeed', 'fields', 'configurations']),
        ...mapGetters('productfeed', ['systemFields', 'customFields']),

        application: {
            get () {
                if (this.form.applicationId && this.form.applicationId.length) {
                    const foundShop = this.shops.find((shop) => shop.applicationId === this.form.applicationId)
                    return foundShop.applicationName
                }
                return ''
            },
            set (value) {
                if (value) {
                    this.form.applicationId = value.applicationId
                }
            },
        },
    },

    async mounted () {
        this.isLoading = true
        this.productFeedId = this.$route.params.productFeedId

        /// Fetch fields
        this.fetchFields()

        /// Fetch configurations
        await this.fetchConfigurations()

        /// If editing get the current product feed by id
        if (this.isEditing) {
            await this.getCurrentProductFeed(this.$route.params.productFeedId)
            this.form = this.currentProductFeed
            this.updateConfiguration()
        } else {
            this.form = {
                applicationId: '',
                configurationId: '',
                countryCodes: [],
                dataFields: 'productHash;sku;quantity',
                language: '',
                productFeedData: '',
                publish: false,
            }
        }
        this.isLoading = false
    },
}
</script>

<style lang="scss" scoped>
  .c-product-feed-content {
    padding: 50px 40px;
  }
</style>
